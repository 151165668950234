"use strict";
import Vue from "vue";
import VueI18n from "vue-i18n";
import { Locales } from "./locales";
Vue.use(VueI18n);
import hu from "./hu.json";
const messages = {
  [Locales.HU]: hu
};
const defaultLocale = Locales.HU;
const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale
});
export default i18n;
