var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Vue, Component } from "vue-property-decorator";
import Navigation from "@/components/layouts/Navigation.vue";
let Layout = class extends Vue {
  get showNav() {
    return this.$route.name !== "login";
  }
};
Layout = __decorateClass([
  Component({
    components: {
      Navigation
    }
  })
], Layout);
export {
  Layout as default
};
