"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Vue } from "vue-property-decorator";
import { getFormData } from "@/utils/helpers";
const listTranslations = (params) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "GET",
    url: "/admin/translations",
    params
  });
});
const createTranslation = (translation) => __async(void 0, null, function* () {
  const formData = getFormData(translation);
  return Vue.axios({
    headers: { "content-type": "multipart/form-data" },
    method: "PUT",
    url: `/admin/translations/`,
    data: formData
  });
});
const deleteTranslation = (key) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "DELETE",
    url: "/admin/translations/",
    data: { key }
  });
});
const getTranslationFile = (params) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "GET",
    url: "/admin/translations/file",
    params
  });
});
const syncTranslations = () => __async(void 0, null, function* () {
  return Vue.axios({
    method: "POST",
    url: "/admin/translations/sync",
    data: {}
  });
});
export default {
  listTranslations,
  createTranslation,
  deleteTranslation,
  getTranslationFile,
  syncTranslations
};
