"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import tagService from "@/store/services/tag";
let TagModule = class extends VuexModule {
  constructor() {
    super(...arguments);
    __publicField(this, "tagItems", []);
    __publicField(this, "pagingInfo", { currentPage: 1, totalPage: 1 });
    __publicField(this, "recommendedTags", []);
    __publicField(this, "tagsLoaded", false);
    __publicField(this, "recommendedTagsLoaded", false);
    __publicField(this, "tagsLoading", true);
    __publicField(this, "recommendedTagsLoading", true);
  }
  searchTags(params) {
    return __async(this, null, function* () {
      this.context.commit("mutateTagsLoadingStatus");
      const { data } = yield tagService.searchTags(params);
      this.context.commit("mutateTags", data);
    });
  }
  listTags() {
    return __async(this, null, function* () {
      this.context.commit("mutateRecommendedTagsLoadingStatus");
      const { data } = yield tagService.listTags();
      this.context.commit("mutateRecommendedTags", data);
    });
  }
  saveTags(_0) {
    return __async(this, arguments, function* ({ tagIds }) {
      this.context.commit("mutateRecommendedTagsLoadingStatus");
      const { data } = yield tagService.saveTags({ tagIds });
      this.context.commit("mutateRecommendedTags", data);
    });
  }
  mutateTags(tags) {
    this.tagItems = tags.items || [];
    this.pagingInfo = tags.pagingInfo || { currentPage: 1, totalPage: 1 };
    this.tagsLoaded = true;
    this.tagsLoading = false;
  }
  mutateRecommendedTags(tags) {
    this.recommendedTags = tags || [];
    this.recommendedTagsLoaded = true;
    this.recommendedTagsLoading = false;
  }
  mutateTagsLoadingStatus() {
    this.tagsLoading = true;
  }
  mutateRecommendedTagsLoadingStatus() {
    this.recommendedTagsLoading = true;
  }
  get getTagItems() {
    return this.tagItems;
  }
  get getPagingInfo() {
    return this.pagingInfo;
  }
  get getRecommendedTags() {
    return this.recommendedTags;
  }
  get isTagsLoaded() {
    return this.tagsLoaded;
  }
  get isRecommendedTagsLoaded() {
    return this.recommendedTagsLoaded;
  }
  get isTagsLoading() {
    return this.tagsLoading;
  }
  get isRecommendedTagsLoading() {
    return this.recommendedTagsLoading;
  }
};
__decorateClass([
  Action({ rawError: true })
], TagModule.prototype, "searchTags", 1);
__decorateClass([
  Action({ rawError: true })
], TagModule.prototype, "listTags", 1);
__decorateClass([
  Action({ rawError: true })
], TagModule.prototype, "saveTags", 1);
__decorateClass([
  Mutation
], TagModule.prototype, "mutateTags", 1);
__decorateClass([
  Mutation
], TagModule.prototype, "mutateRecommendedTags", 1);
__decorateClass([
  Mutation
], TagModule.prototype, "mutateTagsLoadingStatus", 1);
__decorateClass([
  Mutation
], TagModule.prototype, "mutateRecommendedTagsLoadingStatus", 1);
TagModule = __decorateClass([
  Module({ name: "tag", namespaced: true })
], TagModule);
export {
  TagModule as default
};
