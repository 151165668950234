"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Vue } from "vue-property-decorator";
const listEvents = (params) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "GET",
    url: "/admin/couponbooks/stat/redeems",
    params
  });
});
const listCoupons = (params) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "GET",
    url: "/admin/coupons/stat/redeems",
    params
  });
});
const listShops = (params) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "GET",
    url: "/admin/shops/stat/redeems",
    params
  });
});
const listSubscriptionsExcel = () => __async(void 0, null, function* () {
  return Vue.axios({
    method: "GET",
    url: "/admin/subscriptions/excel",
    data: {}
  });
});
const generateSubscriptionsExcel = () => __async(void 0, null, function* () {
  return Vue.axios({
    method: "POST",
    url: "/admin/subscriptions/excel",
    data: {}
  });
});
const listUsersExcel = () => __async(void 0, null, function* () {
  return Vue.axios({
    method: "GET",
    url: "/admin/users/excel",
    data: {}
  });
});
const generateUsersExcel = () => __async(void 0, null, function* () {
  return Vue.axios({
    method: "POST",
    url: "/admin/users/excel",
    data: {}
  });
});
const exportSubscriptions = (params) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "POST",
    url: "/admin/subscriptions/export/pushwize",
    data: params
  });
});
export default {
  listEvents,
  listCoupons,
  listShops,
  listSubscriptionsExcel,
  generateSubscriptionsExcel,
  listUsersExcel,
  generateUsersExcel,
  exportSubscriptions
};
