"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import couponService from "@/store/services/coupon";
let CouponModule = class extends VuexModule {
  constructor() {
    super(...arguments);
    __publicField(this, "couponItems", []);
    __publicField(this, "pagingInfo", { currentPage: 1, totalPage: 1 });
    __publicField(this, "couponsLoaded", false);
    __publicField(this, "loading", false);
  }
  listCoupons(params) {
    return __async(this, null, function* () {
      this.context.commit("mutateLoadingStatus");
      const { data } = yield couponService.listCoupons(params);
      this.context.commit("mutateCoupons", data);
    });
  }
  updateCoupon(_0) {
    return __async(this, arguments, function* ({ id, couponDetails }) {
      yield couponService.updateCoupon(id, couponDetails);
    });
  }
  resetCoupons() {
    this.context.commit("mutateResetCoupons");
  }
  mutateCoupons(coupons) {
    this.couponItems = coupons.items || [];
    this.pagingInfo = coupons.pagingInfo || { currentPage: 1, totalPage: 1 };
    this.couponsLoaded = true;
    this.loading = false;
  }
  mutateResetCoupons() {
    this.couponItems = [];
    this.pagingInfo = { currentPage: 1, totalPage: 1 };
    this.couponsLoaded = false;
  }
  mutateLoadingStatus() {
    this.loading = true;
  }
  get getCouponItems() {
    return this.couponItems;
  }
  get getPagingInfo() {
    return this.pagingInfo;
  }
  get isCouponsLoaded() {
    return this.couponsLoaded;
  }
  get isLoading() {
    return this.loading;
  }
};
__decorateClass([
  Action({ rawError: true })
], CouponModule.prototype, "listCoupons", 1);
__decorateClass([
  Action({ rawError: true })
], CouponModule.prototype, "updateCoupon", 1);
__decorateClass([
  Action({ rawError: true })
], CouponModule.prototype, "resetCoupons", 1);
__decorateClass([
  Mutation
], CouponModule.prototype, "mutateCoupons", 1);
__decorateClass([
  Mutation
], CouponModule.prototype, "mutateResetCoupons", 1);
__decorateClass([
  Mutation
], CouponModule.prototype, "mutateLoadingStatus", 1);
CouponModule = __decorateClass([
  Module({ name: "coupon", namespaced: true })
], CouponModule);
export {
  CouponModule as default
};
