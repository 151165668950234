"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
let AppModule = class extends VuexModule {
  constructor() {
    super(...arguments);
    __publicField(this, "error", null);
    __publicField(this, "hiddenFeatures", false);
  }
  setError(data) {
    this.context.commit("mutateError", data);
  }
  clearError() {
    this.context.commit("mutateError", null);
  }
  showHiddenFeatures() {
    this.context.commit("mutateHiddenFeatures");
  }
  mutateHiddenFeatures() {
    this.hiddenFeatures = true;
  }
  mutateError(error) {
    this.error = error;
  }
  get getError() {
    return this.error || {};
  }
  get isHiddenFeaturesEnabled() {
    return this.hiddenFeatures;
  }
};
__decorateClass([
  Action({ rawError: true })
], AppModule.prototype, "setError", 1);
__decorateClass([
  Action({ rawError: true })
], AppModule.prototype, "clearError", 1);
__decorateClass([
  Action({ rawError: true })
], AppModule.prototype, "showHiddenFeatures", 1);
__decorateClass([
  Mutation
], AppModule.prototype, "mutateHiddenFeatures", 1);
__decorateClass([
  Mutation
], AppModule.prototype, "mutateError", 1);
AppModule = __decorateClass([
  Module({ name: "app", namespaced: true })
], AppModule);
export {
  AppModule as default
};
