"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import authService from "@/store/services/auth";
import { apiModule } from "@/store";
let AuthModule = class extends VuexModule {
  constructor() {
    super(...arguments);
    __publicField(this, "id", null);
    __publicField(this, "email", null);
    __publicField(this, "accessToken", null);
    __publicField(this, "refreshToken", null);
    __publicField(this, "status", null);
  }
  login(_0) {
    return __async(this, arguments, function* ({ email, password }) {
      this.context.commit("loginRequest");
      try {
        const { data } = yield authService.login({ email, password });
        this.context.commit("loginSuccess", data);
      } catch (e) {
        this.context.commit("loginFailed");
      }
    });
  }
  renewToken() {
    return __async(this, null, function* () {
      const { data } = yield authService.renewToken({ refreshToken: this.refreshToken });
      this.context.commit("renewSuccess", data);
    });
  }
  logout() {
    return __async(this, null, function* () {
      if (this.refreshToken && this.refreshToken.length) {
        yield authService.logout({ refreshToken: this.refreshToken });
      }
      this.context.commit("logoutSuccess");
    });
  }
  loginRequest() {
    this.status = "loading";
  }
  loginFailed() {
    this.status = "error";
  }
  loginSuccess(data) {
    this.id = data.id;
    this.email = data.email;
    this.accessToken = data.tokens.access.token;
    this.refreshToken = data.tokens.refresh.token;
    this.status = "loggedin";
    apiModule.setVersion();
  }
  renewSuccess(data) {
    this.accessToken = data.tokens.access.token;
    this.refreshToken = data.tokens.refresh.token;
  }
  logoutSuccess() {
    this.id = null;
    this.email = null;
    this.accessToken = null;
    this.refreshToken = null;
    this.status = null;
  }
  get isAuthenticated() {
    return !!this.accessToken;
  }
  get isLoginError() {
    return this.status === "error";
  }
  get isMondriaanUser() {
    return !!this.email && this.email.includes("@mondriaan.com");
  }
  get getEmail() {
    return this.email || "";
  }
};
__decorateClass([
  Action({ rawError: true })
], AuthModule.prototype, "login", 1);
__decorateClass([
  Action({ rawError: true })
], AuthModule.prototype, "renewToken", 1);
__decorateClass([
  Action({ rawError: true })
], AuthModule.prototype, "logout", 1);
__decorateClass([
  Mutation
], AuthModule.prototype, "loginRequest", 1);
__decorateClass([
  Mutation
], AuthModule.prototype, "loginFailed", 1);
__decorateClass([
  Mutation
], AuthModule.prototype, "loginSuccess", 1);
__decorateClass([
  Mutation
], AuthModule.prototype, "renewSuccess", 1);
__decorateClass([
  Mutation
], AuthModule.prototype, "logoutSuccess", 1);
AuthModule = __decorateClass([
  Module({ name: "auth", namespaced: true })
], AuthModule);
export {
  AuthModule as default
};
