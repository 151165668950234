"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import Vue from "vue";
import VueRouter from "vue-router";
const LoginView = () => import("@/views/auth/Login.vue");
const EventsView = () => import("@/views/events/Events.vue");
const ArchiveEventsView = () => import("@/views/events/ArchiveEvents.vue");
const EventDetailsView = () => import("@/views/events/EventDetails.vue");
const ArchiveEventDetailsView = () => import("@/views/events/ArchiveEventDetails.vue");
const MagazinesView = () => import("@/views/magazines/Magazines.vue");
const MagazineDetailsView = () => import("@/views/magazines/MagazineDetails.vue");
const PromoCodeView = () => import("@/views/products/PromoCode.vue");
const ProductsView = () => import("@/views/products/Products.vue");
const CouponsView = () => import("@/views/application/Coupons.vue");
const CollectionsView = () => import("@/views/application/Collections.vue");
const CollectionDetailsView = () => import("@/views/application/CollectionDetails.vue");
const TagsView = () => import("@/views/application/Tags.vue");
const TranslationsView = () => import("@/views/application/Translations.vue");
const ContentsView = () => import("@/views/application/Contents.vue");
const HomeScreenView = () => import("@/views/application/HomeScreen.vue");
const AdvertisementsView = () => import("@/views/application/Advertisements.vue");
const UniverseCirclesView = () => import("@/views/application/UniverseCircles.vue");
const TestabilityView = () => import("@/views/application/Testability.vue");
const RedeemStatsView = () => import("@/views/statistics/RedeemStats.vue");
const SubscriptionStatsView = () => import("@/views/statistics/SubscriptionStats.vue");
const SubscriptionExportView = () => import("@/views/statistics/SubscriptionExport.vue");
const ReportsView = () => import("@/views/reports/Reports.vue");
const UsersView = () => import("@/views/settings/Users.vue");
const TestersView = () => import("@/views/settings/Testers.vue");
const SyncView = () => import("@/views/settings/Sync.vue");
const SyncDetailsView = () => import("@/views/settings/SyncDetails.vue");
const TracksView = () => import("@/views/settings/Tracks.vue");
const ErrorView = () => import("@/views/error/Error.vue");
const NotFoundView = () => import("@/views/error/NotFound.vue");
import store from "@/store";
import config from "@/config";
Vue.use(VueRouter);
const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters["auth/isAuthenticated"]) {
    next();
    return;
  }
  next("/events");
};
const redirectIfAuthed = (to, from, next) => __async(void 0, null, function* () {
  if (store.getters["auth/isAuthenticated"]) {
    next("/events");
    return;
  }
  next("/login");
});
const ifAuthenticated = (to, from, next) => __async(void 0, null, function* () {
  if (store.getters["api/getVersion"] !== config.PACKAGE_VERSION) {
    yield store.dispatch("auth/logout");
  }
  if (store.getters["auth/isAuthenticated"]) {
    next();
    return;
  }
  next("/login");
});
const routes = [
  {
    path: "/",
    beforeEnter: redirectIfAuthed
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/logout",
    name: "logout",
    beforeEnter(to, from, next) {
      return __async(this, null, function* () {
        yield store.dispatch("auth/logout");
        next("/login");
      });
    }
  },
  {
    path: "/events",
    name: "events",
    component: EventsView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Akt\xEDv esem\xE9nyek"
    }
  },
  {
    path: "/events/archive",
    name: "archive-events",
    component: ArchiveEventsView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Arch\xEDv esem\xE9nyek"
    }
  },
  {
    path: "/events/:id/details",
    name: "event-details",
    component: EventDetailsView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Esem\xE9ny r\xE9szletei"
    }
  },
  {
    path: "/events/archive/:id/details",
    name: "archive-event-details",
    component: ArchiveEventDetailsView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Arch\xEDv esem\xE9ny r\xE9szletei"
    }
  },
  {
    path: "/magazines",
    name: "magazines",
    component: MagazinesView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Magazinok"
    }
  },
  {
    path: "/magazines/:id/details",
    name: "magazine-details",
    component: MagazineDetailsView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Magazin r\xE9szletei"
    }
  },
  {
    path: "/promocode",
    name: "promocode",
    component: PromoCodeView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Promok\xF3d"
    }
  },
  {
    path: "/products",
    name: "products",
    component: ProductsView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "IAP term\xE9kek"
    }
  },
  {
    path: "/coupons",
    name: "coupons",
    component: CouponsView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Kuponok"
    }
  },
  {
    path: "/collections",
    name: "collections",
    component: CollectionsView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Lev\xE1logat\xE1sok"
    }
  },
  {
    path: "/collections/:id/details",
    name: "collection-details",
    component: CollectionDetailsView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Lev\xE1logat\xE1s r\xE9szletei"
    }
  },
  {
    path: "/tags",
    name: "tags",
    component: TagsView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Kiemelt c\xEDmk\xE9k"
    }
  },
  {
    path: "/translations",
    name: "translations",
    component: TranslationsView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Ford\xEDt\xE1sok"
    }
  },
  {
    path: "/contents",
    name: "contents",
    component: ContentsView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Tartalmi sz\xF6vegek"
    }
  },
  {
    path: "/homescreen",
    name: "homescreen",
    component: HomeScreenView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Home k\xE9perny\u0151"
    }
  },
  {
    path: "/advertisements",
    name: "advertisements",
    component: AdvertisementsView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Hirdet\xE9sek"
    }
  },
  {
    path: "/universe-circles",
    name: "universe-circles",
    component: UniverseCirclesView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Univerzum karik\xE1k"
    }
  },
  {
    path: "/testability",
    name: "testability",
    component: TestabilityView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Tesztelhet\u0151s\xE9g"
    }
  },
  {
    path: "/redeem-stats",
    name: "redeem-stats",
    component: RedeemStatsView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Bev\xE1lt\xE1si statisztika"
    }
  },
  {
    path: "/subscription-stats",
    name: "subscription-stats",
    component: SubscriptionStatsView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "El\u0151fizet\u0151i statisztika"
    }
  },
  {
    path: "/subscription-export",
    name: "subscription-export",
    component: SubscriptionExportView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "El\u0151fizet\u0151k export\xE1l\xE1sa"
    }
  },
  {
    path: "/reports",
    name: "reports",
    component: ReportsView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Hibajelent\xE9sek"
    }
  },
  {
    path: "/users",
    name: "users",
    component: UsersView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Felhaszn\xE1l\xF3k"
    }
  },
  {
    path: "/testers",
    name: "testers",
    component: TestersView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Tesztel\u0151k"
    }
  },
  {
    path: "/sync",
    name: "sync",
    component: SyncView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Szinkroniz\xE1l\xE1s"
    }
  },
  {
    path: "/sync/:id/details",
    name: "sync-details",
    component: SyncDetailsView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "Szinkroniz\xE1ci\xF3 r\xE9szletei"
    }
  },
  {
    path: "/tracks",
    name: "tracks",
    component: TracksView,
    beforeEnter: (to, from, next) => {
      ifAuthenticated(to, from, next);
    },
    meta: {
      title: "El\u0151zm\xE9nyek"
    }
  },
  {
    path: "/error",
    name: "error",
    component: ErrorView,
    meta: {
      title: "Hiba t\xF6rt\xE9nt"
    }
  },
  {
    path: "*",
    name: "not-found",
    component: NotFoundView,
    meta: {
      title: "Nem tal\xE1lhat\xF3"
    }
  }
];
const router = new VueRouter({
  mode: "history",
  routes
});
router.beforeEach((to, from, next) => {
  var _a;
  document.title = ((_a = to.meta) == null ? void 0 : _a.title) ? "Glamour Admin | " + to.meta.title : "Glamour Admin";
  next();
});
export default router;
