"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Vue } from "vue-property-decorator";
import { getFormData } from "@/utils/helpers";
const listEvents = (params) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "GET",
    url: "/admin/couponbooks",
    params
  });
});
const uploadEventCover = (id, event) => __async(void 0, null, function* () {
  const formData = getFormData(event);
  return Vue.axios({
    headers: { "content-type": "multipart/form-data" },
    method: "PATCH",
    url: `/admin/couponbooks/${id}`,
    data: formData
  });
});
const updateEvent = (id, event) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "PATCH",
    url: `/admin/couponbooks/${id}`,
    data: event
  });
});
const sendEmail = (data) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "POST",
    url: "/admin/couponbooks/sendEmailStatistics",
    data
  });
});
export default {
  listEvents,
  uploadEventCover,
  updateEvent,
  sendEmail
};
