"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Vue } from "vue-property-decorator";
const login = (_0) => __async(void 0, [_0], function* ({ email, password }) {
  return Vue.axios({
    method: "POST",
    url: "/admin/auth/login",
    data: { email, password }
  });
});
const renewToken = (_0) => __async(void 0, [_0], function* ({ refreshToken }) {
  return Vue.axios({
    method: "POST",
    url: "/admin/auth/renew-token",
    data: { refreshToken },
    preventRefresh: true
  });
});
const logout = (_0) => __async(void 0, [_0], function* ({ refreshToken }) {
  return Vue.axios({
    method: "POST",
    url: "/admin/auth/logout",
    data: { refreshToken },
    isLogout: true
  });
});
export default {
  login,
  renewToken,
  logout
};
