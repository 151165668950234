"use strict";
import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Datepicker from "vue2-datepicker";
import "vue2-datepicker/locale/hu";
import vSelect from "vue-select";
import App from "./App.vue";
import router from "./router";
import "./utils/axios";
import store from "./store";
import i18n from "@/i18n";
import "./assets/scss/main.scss";
vSelect.props.components.default = () => ({
  Deselect: {
    /* @ts-ignore */
    render: (createElement) => createElement("span", "")
  },
  OpenIndicator: {
    /* @ts-ignore */
    render: (createElement) => createElement("span", "")
  }
});
Vue.use(BootstrapVue);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("DatePicker", Datepicker);
Vue.component("v-select", vSelect);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
