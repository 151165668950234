"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import collectionService from "@/store/services/collection";
let CollectionModule = class extends VuexModule {
  constructor() {
    super(...arguments);
    __publicField(this, "collections", []);
    __publicField(this, "collectionsLoaded", false);
    __publicField(this, "collectionLoaded", false);
    __publicField(this, "collectionsById", {});
    __publicField(this, "filteredCouponItems", []);
    __publicField(this, "filteredPagingInfo", { currentPage: 1, totalPage: 1 });
    __publicField(this, "filteredCouponsLoaded", false);
    __publicField(this, "filteredCouponsLoading", false);
  }
  listCollections() {
    return __async(this, null, function* () {
      const { data } = yield collectionService.listCollections();
      this.context.commit("mutateCollections", data);
    });
  }
  getCollection(id) {
    return __async(this, null, function* () {
      const { data } = yield collectionService.getCollection(id);
      this.context.commit("mutateCollection", data);
    });
  }
  getFilteredCoupons(_0) {
    return __async(this, arguments, function* ({ params, available, automaticFilters }) {
      this.context.commit("mutateFilteredCouponsLoadingStatus", true);
      const { data } = yield collectionService.getFilteredCoupons(params, { available, automaticFilters });
      this.context.commit("mutateFilteredCoupons", data);
    });
  }
  resetFilteredCoupons() {
    this.context.commit("mutateResetFilteredCoupons");
  }
  createCollection(collection) {
    return __async(this, null, function* () {
      const { data } = yield collectionService.createCollection(collection);
      return data;
    });
  }
  updateCollection(_0) {
    return __async(this, arguments, function* ({ id, collection }) {
      const { data } = yield collectionService.updateCollection(id, collection);
      return data;
    });
  }
  deleteCollection(id) {
    return __async(this, null, function* () {
      yield collectionService.deleteCollection(id);
    });
  }
  mutateCollections(collections) {
    this.collections = collections;
    this.collectionsById = collections.reduce((acc, collection) => __spreadProps(__spreadValues({}, acc), { [collection.id]: collection }), {});
    this.collectionsLoaded = true;
  }
  mutateCollection(collection) {
    this.collectionsById = __spreadProps(__spreadValues({}, this.collectionsById), { [collection.id]: collection });
    this.collectionLoaded = true;
  }
  mutateFilteredCouponsLoadingStatus(status = false) {
    this.filteredCouponsLoading = status;
  }
  mutateFilteredCoupons(coupons) {
    this.filteredCouponItems = coupons.items || [];
    this.filteredPagingInfo = coupons.pagingInfo || { currentPage: 1, totalPage: 1 };
    this.filteredCouponsLoaded = true;
    this.filteredCouponsLoading = false;
  }
  mutateResetFilteredCoupons() {
    this.filteredCouponItems = [];
    this.filteredPagingInfo = { currentPage: 1, totalPage: 1 };
    this.filteredCouponsLoaded = false;
  }
  get getCollections() {
    return this.collections || [];
  }
  get isCollectionsLoaded() {
    return this.collectionsLoaded;
  }
  get isCollectionLoaded() {
    return this.collectionLoaded;
  }
  get getFilteredCouponItems() {
    return this.filteredCouponItems;
  }
  get getFilteredPagingInfo() {
    return this.filteredPagingInfo;
  }
  get isFilteredCouponsLoaded() {
    return this.filteredCouponsLoaded;
  }
  get isFilteredCouponsLoading() {
    return this.filteredCouponsLoading;
  }
  get getCollectionById() {
    return (collectionId) => this.collectionsById[collectionId] || {};
  }
};
__decorateClass([
  Action({ rawError: true })
], CollectionModule.prototype, "listCollections", 1);
__decorateClass([
  Action({ rawError: true })
], CollectionModule.prototype, "getCollection", 1);
__decorateClass([
  Action({ rawError: true })
], CollectionModule.prototype, "getFilteredCoupons", 1);
__decorateClass([
  Action({ rawError: true })
], CollectionModule.prototype, "resetFilteredCoupons", 1);
__decorateClass([
  Action({ rawError: true })
], CollectionModule.prototype, "createCollection", 1);
__decorateClass([
  Action({ rawError: true })
], CollectionModule.prototype, "updateCollection", 1);
__decorateClass([
  Action({ rawError: true })
], CollectionModule.prototype, "deleteCollection", 1);
__decorateClass([
  Mutation
], CollectionModule.prototype, "mutateCollections", 1);
__decorateClass([
  Mutation
], CollectionModule.prototype, "mutateCollection", 1);
__decorateClass([
  Mutation
], CollectionModule.prototype, "mutateFilteredCouponsLoadingStatus", 1);
__decorateClass([
  Mutation
], CollectionModule.prototype, "mutateFilteredCoupons", 1);
__decorateClass([
  Mutation
], CollectionModule.prototype, "mutateResetFilteredCoupons", 1);
CollectionModule = __decorateClass([
  Module({ name: "collection", namespaced: true })
], CollectionModule);
export {
  CollectionModule as default
};
