"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import moment from "moment";
import i18n from "@/i18n";
export const validateObserver = (ref) => __async(void 0, null, function* () {
  return (yield ref).validate();
});
export const resetObserver = (ref) => __async(void 0, null, function* () {
  return (yield ref).reset();
});
export const formatDate = (date = void 0) => {
  const value = date ? new Date(date) : /* @__PURE__ */ new Date();
  return moment(value).format("YYYY-MM-DD HH:mm:ss");
};
export const customTimeFormat = {
  stringify: (date) => {
    return date ? moment(date).utc(true).format("YYYY-MM-DD HH:mm:ss") : null;
  },
  parse: (value) => {
    return value ? moment(value, "YYYY-MM-DD HH:mm:ssZ").toDate() : null;
  }
};
export const defaultStartTime = () => {
  return (/* @__PURE__ */ new Date()).setHours(0, 0, 0);
};
export const defaultEndTime = () => {
  return (/* @__PURE__ */ new Date()).setHours(23, 59, 59);
};
export const defaultIntervalTime = () => {
  return [defaultStartTime(), defaultEndTime()];
};
export const dateToIso = (date, keepOffset = true) => {
  return date ? moment(date).toISOString(keepOffset) : null;
};
export const isBetweenDates = (dateFrom, dateTo, dateCurrent = /* @__PURE__ */ new Date()) => {
  const current = moment(dateCurrent, "YYYY-MM-DD HH:mm:ss").valueOf();
  const from = dateFrom ? moment(dateFrom, "YYYY-MM-DD HH:mm:ss").utc(true).valueOf() : Number.MIN_SAFE_INTEGER;
  const to = dateTo ? moment(dateTo, "YYYY-MM-DD HH:mm:ss").utc(true).valueOf() : Number.MAX_SAFE_INTEGER;
  return from <= current && current <= to;
};
export const isDateRangesOverlap = (dateOneFrom, dateOneTo, dateTwoFrom, dateTwoTo) => {
  const oneFrom = dateOneFrom ? moment(dateOneFrom, "YYYY-MM-DD HH:mm:ss").utc(true).valueOf() : Number.MIN_SAFE_INTEGER;
  const oneTo = dateOneTo ? moment(dateOneTo, "YYYY-MM-DD HH:mm:ss").utc(true).valueOf() : Number.MAX_SAFE_INTEGER;
  const twoFrom = dateTwoFrom ? moment(dateTwoFrom, "YYYY-MM-DD HH:mm:ss").utc(true).valueOf() : Number.MIN_SAFE_INTEGER;
  const twoTo = dateTwoTo ? moment(dateTwoTo, "YYYY-MM-DD HH:mm:ss").utc(true).valueOf() : Number.MAX_SAFE_INTEGER;
  return twoTo >= oneFrom && twoFrom <= oneTo;
};
export const getFormData = (object, form, namespace) => {
  const formData = form || new FormData();
  for (const property in object) {
    const formKey = namespace ? `${namespace}[${property}]` : property;
    if (typeof object[property] === "object" && !(object[property] instanceof File)) {
      getFormData(object[property], formData, formKey);
    } else {
      formData.append(formKey, object[property]);
    }
  }
  return formData;
};
export const downloadFile = (fileName, content, fileType = "text/plain") => {
  const blob = content instanceof Blob ? new Blob([content]) : new Blob([content], { type: fileType });
  const a = document.createElement("a");
  a.download = fileName;
  if (fileType.length === 0) {
    a.href = content;
  } else {
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = [fileType, a.download, a.href].join(":");
  }
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(() => URL.revokeObjectURL(a.href), 1500);
};
export const downloadFileFromUrl = (url, filename) => __async(void 0, null, function* () {
  try {
    const response = yield fetch(url);
    if (!response.ok) {
      new Error(`HTTP error! status: ${response.status}`);
    }
    const data = yield response.blob();
    let mimeType = "";
    if (filename.endsWith(".csv")) {
      mimeType = "text/csv";
    } else if (filename.endsWith(".xlsx")) {
      mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    } else {
      new Error("Unsupported file type");
    }
    const blob = new Blob([data], { type: mimeType });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
});
export const sum = (array, key, secondaryKey = "") => {
  if (secondaryKey.length) {
    return array.reduce((a, b) => a + (b[key][secondaryKey] || 0), 0);
  }
  return array.reduce((a, b) => a + (b[key] || 0), 0);
};
export const urlTypes = {
  link: "",
  article: "glamour://article?id=",
  coupon: "glamour://coupon?id=",
  collection: "glamour://filter_to_collection?collection=",
  purchase: "glamour://purchase",
  usePromocode: "glamour://promocode?promocode=",
  promocode: "glamour://promocode",
  magazine: "glamour://magazine_screen",
  shopping: "glamour://shopping_screen",
  allCoupons: "glamour://all_coupons",
  allEvents: "glamour://all_events",
  latestArticles: "glamour://latest_articles",
  articleTag: "glamour://filter_to_article_tag?tag_id=",
  categories: "glamour://categories",
  allMagazines: "glamour://all_magazines",
  couponbook: "glamour://couponbook?couponbook_id=",
  profile: "glamour://profile_screen",
  shopFinder: "glamour://shop_finder",
  tag: "glamour://filter_to_tag?"
};
export const urlOptions = [
  { value: "article", text: i18n.t("application.deeplink.type.article"), param: true },
  { value: "articleTag", text: i18n.t("application.deeplink.type.articleTag"), param: true },
  { value: "tag", text: i18n.t("application.deeplink.type.tag"), param: true },
  { value: "purchase", text: i18n.t("application.deeplink.type.purchase") },
  { value: "usePromocode", text: i18n.t("application.deeplink.type.usePromocode"), param: true },
  { value: "promocode", text: i18n.t("application.deeplink.type.promocode") },
  { value: "categories", text: i18n.t("application.deeplink.type.categories") },
  { value: "coupon", text: i18n.t("application.deeplink.type.coupon"), param: true },
  { value: "couponbook", text: i18n.t("application.deeplink.type.couponbook"), param: true },
  { value: "latestArticles", text: i18n.t("application.deeplink.type.latestArticles") },
  { value: "collection", text: i18n.t("application.deeplink.type.collection"), param: true },
  { value: "link", text: i18n.t("application.deeplink.type.link"), param: true },
  { value: "magazine", text: i18n.t("application.deeplink.type.magazine") },
  { value: "allEvents", text: i18n.t("application.deeplink.type.allEvents") },
  { value: "allCoupons", text: i18n.t("application.deeplink.type.allCoupons") },
  { value: "allMagazines", text: i18n.t("application.deeplink.type.allMagazines") },
  { value: "profile", text: i18n.t("application.deeplink.type.profile") },
  { value: "shopping", text: i18n.t("application.deeplink.type.shopping") },
  { value: "shopFinder", text: i18n.t("application.deeplink.type.shopFinder") }
];
export const parseUrl = (inputUrl) => {
  let urlType = "link";
  let tagType = "";
  let urlValue = "";
  Object.keys(urlTypes).forEach((type) => {
    const pattern = urlTypes[type];
    if (type !== "link" && inputUrl.includes(pattern)) {
      urlType = type;
      if (type === "tag") {
        const params = Object.values(Object.fromEntries(new URLSearchParams(inputUrl).entries()));
        tagType = params[0] || "";
        urlValue = params[1] || "";
        return;
      }
      if (type === "promocode") {
        urlType = inputUrl.includes(`?${type}`) ? "usePromocode" : "promocode";
        urlValue = urlTypes[urlType] ? inputUrl.replace(urlTypes[urlType], "") : "";
        return;
      }
      urlValue = urlTypes[type] ? inputUrl.replace(urlTypes[type], "") : "";
    }
    if (type === "link") {
      urlType = type;
      urlValue = inputUrl;
    }
  });
  return { urlType, tagType, urlValue };
};
export const getUrlType = (url) => {
  const parsedUrl = parseUrl(url);
  const urlOption = urlOptions.find((option) => option.value === parsedUrl.urlType);
  return urlOption ? urlOption.text : "\u2013";
};
export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}
