"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import contentService from "@/store/services/content";
let ContentModule = class extends VuexModule {
  constructor() {
    super(...arguments);
    __publicField(this, "contents", []);
    __publicField(this, "contentsLoaded", false);
  }
  listContents() {
    return __async(this, null, function* () {
      this.context.commit("mutateLoadedStatus");
      const { data } = yield contentService.listContents();
      this.context.commit("mutateContents", data);
    });
  }
  updateContent(_0) {
    return __async(this, arguments, function* ({ fragment, incrementVersion }) {
      yield contentService.updateContent(fragment, incrementVersion);
    });
  }
  mutateContents(contents) {
    this.contents = contents;
    this.contentsLoaded = true;
  }
  mutateLoadedStatus(status = false) {
    this.contentsLoaded = status;
  }
  get getContents() {
    return this.contents || [];
  }
  get isContentsLoaded() {
    return this.contentsLoaded;
  }
};
__decorateClass([
  Action({ rawError: true })
], ContentModule.prototype, "listContents", 1);
__decorateClass([
  Action({ rawError: true })
], ContentModule.prototype, "updateContent", 1);
__decorateClass([
  Mutation
], ContentModule.prototype, "mutateContents", 1);
__decorateClass([
  Mutation
], ContentModule.prototype, "mutateLoadedStatus", 1);
ContentModule = __decorateClass([
  Module({ name: "content", namespaced: true })
], ContentModule);
export {
  ContentModule as default
};
