"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Vue } from "vue-property-decorator";
const listCollections = () => __async(void 0, null, function* () {
  return Vue.axios({
    method: "GET",
    url: "/admin/couponCollections"
  });
});
const getCollection = (id) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "GET",
    url: `/admin/couponCollections/${id}`
  });
});
const createCollection = (data) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "POST",
    url: "/admin/couponCollections",
    data
  });
});
const updateCollection = (id, data) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "PATCH",
    url: `/admin/couponCollections/${id}`,
    data
  });
});
const deleteCollection = (id) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "DELETE",
    url: `/admin/couponCollections/${id}`
  });
});
const getFilteredCoupons = (params, data) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "POST",
    url: "/admin/couponCollections/filteredCoupons",
    params,
    data
  });
});
export default {
  listCollections,
  getCollection,
  createCollection,
  updateCollection,
  deleteCollection,
  getFilteredCoupons
};
