"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import universeCircleService from "@/store/services/universeCircle";
let UniverseCircleModule = class extends VuexModule {
  constructor() {
    super(...arguments);
    __publicField(this, "universeCircles", []);
    __publicField(this, "universeCirclesLoaded", false);
    __publicField(this, "updatingUniverseCircles", false);
    __publicField(this, "universeCirclesById", {});
  }
  listUniverseCircles() {
    return __async(this, null, function* () {
      this.context.commit("mutateUpdatingUniverseCircles");
      const { data } = yield universeCircleService.listUniverseCircles();
      this.context.commit("mutateUniverseCircles", data);
    });
  }
  createUniverseCircle(universeCircle) {
    return __async(this, null, function* () {
      const { data } = yield universeCircleService.createUniverseCircle(universeCircle);
      return data;
    });
  }
  updateUniverseCircle(_0) {
    return __async(this, arguments, function* ({ id, universeCircle }) {
      yield universeCircleService.updateUniverseCircle(id, universeCircle);
    });
  }
  deleteUniverseCircle(_0) {
    return __async(this, arguments, function* ({ id }) {
      yield universeCircleService.deleteUniverseCircle(id);
    });
  }
  updateUniverseCircleOrder(_0) {
    return __async(this, arguments, function* ({ id, universeCircle }) {
      this.context.commit("mutateUpdatingUniverseCircles");
      yield universeCircleService.updateUniverseCircle(id, universeCircle);
      this.context.commit("mutateUpdatingUniverseCircles", false);
    });
  }
  mutateUniverseCircles(universeCircles) {
    this.universeCircles = universeCircles.sort((a, b) => a.order > b.order ? 1 : -1);
    this.universeCirclesById = universeCircles.reduce((acc, universeCircle) => __spreadProps(__spreadValues({}, acc), { [universeCircle.id]: universeCircle }), {});
    this.universeCirclesLoaded = true;
    this.updatingUniverseCircles = false;
  }
  mutateLoadedStatus(status = false) {
    this.universeCirclesLoaded = status;
  }
  mutateUpdatingUniverseCircles(status = true) {
    this.updatingUniverseCircles = status;
  }
  get getUniverseCircles() {
    return this.universeCircles || [];
  }
  get isUniverseCirclesLoaded() {
    return this.universeCirclesLoaded;
  }
  get isUniverseCirclesUpdating() {
    return this.updatingUniverseCircles;
  }
  get getUniverseCircleById() {
    return (universeCircleId) => this.universeCirclesById[universeCircleId] || {};
  }
};
__decorateClass([
  Action({ rawError: true })
], UniverseCircleModule.prototype, "listUniverseCircles", 1);
__decorateClass([
  Action({ rawError: true })
], UniverseCircleModule.prototype, "createUniverseCircle", 1);
__decorateClass([
  Action({ rawError: true })
], UniverseCircleModule.prototype, "updateUniverseCircle", 1);
__decorateClass([
  Action({ rawError: true })
], UniverseCircleModule.prototype, "deleteUniverseCircle", 1);
__decorateClass([
  Action({ rawError: true })
], UniverseCircleModule.prototype, "updateUniverseCircleOrder", 1);
__decorateClass([
  Mutation
], UniverseCircleModule.prototype, "mutateUniverseCircles", 1);
__decorateClass([
  Mutation
], UniverseCircleModule.prototype, "mutateLoadedStatus", 1);
__decorateClass([
  Mutation
], UniverseCircleModule.prototype, "mutateUpdatingUniverseCircles", 1);
UniverseCircleModule = __decorateClass([
  Module({ name: "universeCircle", namespaced: true })
], UniverseCircleModule);
export {
  UniverseCircleModule as default
};
