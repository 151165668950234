"use strict";
export var Locales = /* @__PURE__ */ ((Locales2) => {
  Locales2["HU"] = "hu";
  Locales2["EN"] = "en";
  return Locales2;
})(Locales || {});
export const LOCALES = [
  { value: "hu" /* HU */, caption: "Magyar" },
  { value: "en" /* EN */, caption: "English" }
];
