"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import layoutService from "@/store/services/layout";
let LayoutModule = class extends VuexModule {
  constructor() {
    super(...arguments);
    __publicField(this, "layout", { blocks: [] });
    __publicField(this, "layoutLoaded", false);
    __publicField(this, "layoutLoading", false);
  }
  listLayout() {
    return __async(this, null, function* () {
      this.context.commit("mutateLoadingStatus");
      const { data } = yield layoutService.getHomeLayout();
      this.context.commit("mutateContents", data);
    });
  }
  updateLayout(layout) {
    return __async(this, null, function* () {
      yield layoutService.updateHomeLayout(layout);
    });
  }
  mutateContents(layout) {
    this.layout = layout;
    this.layoutLoaded = true;
    this.layoutLoading = false;
  }
  mutateLoadingStatus() {
    this.layoutLoading = true;
  }
  get getLayout() {
    return this.layout || { blocks: [] };
  }
  get isLayoutLoaded() {
    return this.layoutLoaded;
  }
  get isLayoutLoading() {
    return this.layoutLoading;
  }
};
__decorateClass([
  Action({ rawError: true })
], LayoutModule.prototype, "listLayout", 1);
__decorateClass([
  Action({ rawError: true })
], LayoutModule.prototype, "updateLayout", 1);
__decorateClass([
  Mutation
], LayoutModule.prototype, "mutateContents", 1);
__decorateClass([
  Mutation
], LayoutModule.prototype, "mutateLoadingStatus", 1);
LayoutModule = __decorateClass([
  Module({ name: "layout", namespaced: true })
], LayoutModule);
export {
  LayoutModule as default
};
