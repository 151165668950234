"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import eventService from "@/store/services/event";
let EventModule = class extends VuexModule {
  constructor() {
    super(...arguments);
    __publicField(this, "events", []);
    __publicField(this, "eventsLoaded", false);
    __publicField(this, "updatingEvents", false);
    __publicField(this, "eventsById", {});
    __publicField(this, "archiveEvents", []);
    __publicField(this, "archiveEventsLoaded", false);
    __publicField(this, "archiveEventsById", {});
    __publicField(this, "uploadStatus", null);
  }
  listEvents(active = true) {
    return __async(this, null, function* () {
      const now = /* @__PURE__ */ new Date();
      let params;
      if (active) {
        this.context.commit("mutateUpdatingEvents");
        params = { availableAt: now.toISOString() };
        const { data } = yield eventService.listEvents(params);
        this.context.commit("mutateEvents", data);
      } else {
        params = { isArchive: true };
        const { data } = yield eventService.listEvents(params);
        this.context.commit("mutateArchiveEvents", data);
      }
    });
  }
  listEventsByDate(date) {
    return __async(this, null, function* () {
      const params = { activeAt: date, includeDeleted: true, calcStat: true };
      const { data } = yield eventService.listEvents(params);
      return data;
    });
  }
  uploadEventCover(_0) {
    return __async(this, arguments, function* ({ id, event }) {
      this.context.commit("mutateUploadStatus", "inprogress");
      try {
        yield eventService.uploadEventCover(id, event);
        this.context.commit("mutateUploadStatus", "success");
      } catch (e) {
        this.context.commit("mutateUploadStatus", "error");
      }
    });
  }
  sendEmail(notification) {
    return __async(this, null, function* () {
      const { data } = yield eventService.sendEmail(notification);
      return data;
    });
  }
  updateEvent(_0) {
    return __async(this, arguments, function* ({ id, event }) {
      this.context.commit("mutateUpdatingEvents");
      yield eventService.updateEvent(id, event);
      this.context.commit("mutateUpdatingEvents", false);
    });
  }
  mutateEvents(events) {
    this.events = events;
    this.eventsById = events.reduce((acc, event) => __spreadProps(__spreadValues({}, acc), { [event.id]: event }), {});
    this.eventsLoaded = true;
    this.updatingEvents = false;
  }
  mutateArchiveEvents(events) {
    this.archiveEvents = events;
    this.archiveEventsById = events.reduce((acc, event) => __spreadProps(__spreadValues({}, acc), { [event.id]: event }), {});
    this.archiveEventsLoaded = true;
  }
  mutateUploadStatus(status) {
    this.uploadStatus = status;
  }
  mutateUpdatingEvents(status = true) {
    this.updatingEvents = status;
  }
  get getEvents() {
    return this.events || [];
  }
  get getArchiveEvents() {
    return this.archiveEvents || [];
  }
  get isEventsLoaded() {
    return this.eventsLoaded;
  }
  get isArchiveEventsLoaded() {
    return this.archiveEventsLoaded;
  }
  get isEventsUpdating() {
    return this.updatingEvents;
  }
  get getEventById() {
    return (eventId) => this.eventsById[eventId] || {};
  }
  get getArchiveEventById() {
    return (eventId) => this.archiveEventsById[eventId] || {};
  }
  get isUploadInProgress() {
    return this.uploadStatus === "inprogress";
  }
  get isUploadFailed() {
    return this.uploadStatus === "error";
  }
};
__decorateClass([
  Action({ rawError: true })
], EventModule.prototype, "listEvents", 1);
__decorateClass([
  Action({ rawError: true })
], EventModule.prototype, "listEventsByDate", 1);
__decorateClass([
  Action({ rawError: true })
], EventModule.prototype, "uploadEventCover", 1);
__decorateClass([
  Action({ rawError: true })
], EventModule.prototype, "sendEmail", 1);
__decorateClass([
  Action({ rawError: true })
], EventModule.prototype, "updateEvent", 1);
__decorateClass([
  Mutation
], EventModule.prototype, "mutateEvents", 1);
__decorateClass([
  Mutation
], EventModule.prototype, "mutateArchiveEvents", 1);
__decorateClass([
  Mutation
], EventModule.prototype, "mutateUploadStatus", 1);
__decorateClass([
  Mutation
], EventModule.prototype, "mutateUpdatingEvents", 1);
EventModule = __decorateClass([
  Module({ name: "event", namespaced: true })
], EventModule);
export {
  EventModule as default
};
