"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Vue } from "vue-property-decorator";
import axios from "axios";
import VueAxios from "vue-axios";
import qs from "qs";
import store from "@/store";
import router from "@/router";
import config from "@/config";
Vue.use(VueAxios, axios);
axios.defaults.baseURL = config.API_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.responseType = "json";
axios.interceptors.request.handlers = [];
axios.interceptors.response.handlers = [];
const renewAccessToken = () => __async(this, null, function* () {
  yield store.dispatch("auth/renewToken");
});
const logout = () => __async(this, null, function* () {
  yield router.push({ name: "logout" });
});
axios.interceptors.request.use((request) => __async(this, null, function* () {
  const { accessToken } = store.state.auth;
  const isAuthed = !!accessToken;
  if (isAuthed) {
    request.headers.authorization = `Bearer ${accessToken}`;
  }
  request.headers["accept-language"] = "en";
  return request;
}));
axios.interceptors.request.use((config2) => {
  config2.paramsSerializer = (params) => {
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false,
      skipNulls: true
    });
  };
  return config2;
});
axios.interceptors.response.use((response) => response, (error) => __async(this, null, function* () {
  if (error.seen) {
    throw error;
  }
  console.error(error);
  const { status } = error.response;
  const { errorCode } = error.response.data;
  const originalRequest = error.response.config;
  if (originalRequest.isLogout) {
    return true;
  }
  if (status === 404) {
    yield router.push({ name: "error" });
  } else if (status === 401) {
    if (error.response.data instanceof Blob) {
      yield renewAccessToken();
      return yield axios.request(originalRequest);
    }
    switch (errorCode) {
      case "badcredentials":
        if (originalRequest.preventRefresh) {
          yield logout();
        }
        yield renewAccessToken();
        return yield axios.request(originalRequest);
      default:
        yield logout();
    }
  } else if (status === 400) {
    error.response.data.errorDetails.find((e) => __async(this, null, function* () {
      if (e.message === "user not found") {
        yield store.dispatch("tester/setError", "missing");
        return;
      }
      if (e.message === "user is already a tester") {
        yield store.dispatch("tester/setError", "existing");
        return;
      }
      if (e.message.includes("List already exists")) {
        yield store.dispatch("statistic/setError", true);
        return;
      }
      yield store.dispatch("app/setError", error.response.data);
    }));
  } else {
    yield store.dispatch("app/setError", error.response.data);
  }
  error.seen = true;
  throw error;
}));
