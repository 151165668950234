"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import userService from "@/store/services/user";
let UserModule = class extends VuexModule {
  constructor() {
    super(...arguments);
    __publicField(this, "users", []);
    __publicField(this, "pagingInfo", { currentPage: 1, totalPage: 1 });
    __publicField(this, "usersLoaded", false);
    __publicField(this, "usersLoading", true);
  }
  listUsers(params) {
    return __async(this, null, function* () {
      this.context.commit("mutateUsersLoadingStatus");
      const { data } = yield userService.listUsers(params);
      this.context.commit("mutateUsers", data);
    });
  }
  createUser(user) {
    return __async(this, null, function* () {
      yield userService.createUser(user);
    });
  }
  updateUser(_0) {
    return __async(this, arguments, function* ({ id, user }) {
      yield userService.updateUser(id, user);
    });
  }
  deleteUser(_0) {
    return __async(this, arguments, function* ({ id }) {
      yield userService.deleteUser(id);
    });
  }
  mutateUsers(users) {
    this.users = users.items || [];
    this.pagingInfo = users.pagingInfo || { currentPage: 1, totalPage: 1 };
    this.usersLoaded = true;
    this.usersLoading = false;
  }
  mutateUsersLoadingStatus() {
    this.usersLoading = true;
  }
  get getUsers() {
    return this.users;
  }
  get getPagingInfo() {
    return this.pagingInfo;
  }
  get isUsersLoaded() {
    return this.usersLoaded;
  }
  get isUsersLoading() {
    return this.usersLoading;
  }
};
__decorateClass([
  Action({ rawError: true })
], UserModule.prototype, "listUsers", 1);
__decorateClass([
  Action({ rawError: true })
], UserModule.prototype, "createUser", 1);
__decorateClass([
  Action({ rawError: true })
], UserModule.prototype, "updateUser", 1);
__decorateClass([
  Action({ rawError: true })
], UserModule.prototype, "deleteUser", 1);
__decorateClass([
  Mutation
], UserModule.prototype, "mutateUsers", 1);
__decorateClass([
  Mutation
], UserModule.prototype, "mutateUsersLoadingStatus", 1);
UserModule = __decorateClass([
  Module({ name: "user", namespaced: true })
], UserModule);
export {
  UserModule as default
};
