"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import Vue from "vue";
import Vuex from "vuex";
import { getModule } from "vuex-module-decorators";
import createPersistedState from "vuex-persistedstate";
import AdvertisementModule from "@/store/modules/advertisement";
import ApiModule from "@/store/modules/api";
import AppModule from "@/store/modules/app";
import AuthModule from "@/store/modules/auth";
import CollectionModule from "@/store/modules/collection";
import ContentModule from "@/store/modules/content";
import CouponModule from "@/store/modules/coupon";
import EventModule from "@/store/modules/event";
import JobModule from "@/store/modules/job";
import LayoutModule from "@/store/modules/layout";
import MagazineModule from "@/store/modules/magazine";
import ProductModule from "@/store/modules/product";
import PromocodeModule from "@/store/modules/promocode";
import ReportModule from "@/store/modules/report";
import StatisticModule from "@/store/modules/statistic";
import TagModule from "@/store/modules/tag";
import TesterModule from "@/store/modules/tester";
import TrackModule from "@/store/modules/track";
import TranslationModule from "@/store/modules/translation";
import UniverseCircleModule from "@/store/modules/universeCircle";
import UserModule from "@/store/modules/user";
Vue.use(Vuex);
const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      reducer(state) {
        if (!state.auth.accessToken) {
          return {};
        }
        return __spreadValues({}, state);
      }
    })
  ],
  modules: {
    advertisement: AdvertisementModule,
    api: ApiModule,
    app: AppModule,
    auth: AuthModule,
    collection: CollectionModule,
    content: ContentModule,
    coupon: CouponModule,
    event: EventModule,
    job: JobModule,
    layout: LayoutModule,
    magazine: MagazineModule,
    product: ProductModule,
    promocode: PromocodeModule,
    report: ReportModule,
    statistic: StatisticModule,
    tag: TagModule,
    tester: TesterModule,
    track: TrackModule,
    translation: TranslationModule,
    universeCircle: UniverseCircleModule,
    user: UserModule
  },
  state: {},
  getters: {},
  mutations: {}
});
export default store;
export const advertisementModule = getModule(AdvertisementModule, store);
export const apiModule = getModule(ApiModule, store);
export const appModule = getModule(AppModule, store);
export const authModule = getModule(AuthModule, store);
export const collectionModule = getModule(CollectionModule, store);
export const contentModule = getModule(ContentModule, store);
export const couponModule = getModule(CouponModule, store);
export const eventModule = getModule(EventModule, store);
export const jobModule = getModule(JobModule, store);
export const layoutModule = getModule(LayoutModule, store);
export const magazineModule = getModule(MagazineModule, store);
export const productModule = getModule(ProductModule, store);
export const promocodeModule = getModule(PromocodeModule, store);
export const reportModule = getModule(ReportModule, store);
export const statisticModule = getModule(StatisticModule, store);
export const tagModule = getModule(TagModule, store);
export const testerModule = getModule(TesterModule, store);
export const trackModule = getModule(TrackModule, store);
export const translationModule = getModule(TranslationModule, store);
export const universeCircleModule = getModule(UniverseCircleModule, store);
export const userModule = getModule(UserModule, store);
