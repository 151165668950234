var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Vue, Component } from "vue-property-decorator";
import Layout from "@/components/layouts/Layout.vue";
import ErrorModal from "@/components/ui/common/ErrorModal.vue";
import { extend } from "vee-validate";
import { required, max, max_value, min, min_value, email } from "vee-validate/dist/rules";
let App = class extends Vue {
  created() {
    const password = {
      validate(value) {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value);
      }
    };
    const number = {
      validate(value) {
        return /^\d+$/.test(value);
      }
    };
    extend("required", __spreadProps(__spreadValues({}, required), { message: () => this.$t("validation.required") }));
    extend("min", __spreadProps(__spreadValues({}, min), { message: (value, params) => this.$t("validation.min", { value: params.length }) }));
    extend("max", __spreadProps(__spreadValues({}, max), { message: (value, params) => this.$t("validation.max", { value: params.length }) }));
    extend("min_value", __spreadProps(__spreadValues({}, min_value), { message: (_, { min: value }) => this.$t("validation.minValue", { value }) }));
    extend("max_value", __spreadProps(__spreadValues({}, max_value), { message: (_, { max: value }) => this.$t("validation.maxValue", { value }) }));
    extend("email", __spreadProps(__spreadValues({}, email), { message: () => this.$t("validation.email") }));
    extend("number", __spreadProps(__spreadValues({}, number), { message: () => this.$t("validation.number") }));
    extend("password", __spreadProps(__spreadValues({}, password), { message: () => this.$t("validation.password") }));
  }
};
App = __decorateClass([
  Component({
    components: {
      Layout,
      ErrorModal
    }
  })
], App);
export {
  App as default
};
