"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Vue } from "vue-property-decorator";
const listJobs = () => __async(void 0, null, function* () {
  return Vue.axios({
    method: "GET",
    url: "/admin/jobs"
  });
});
const listJob = (uuid) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "GET",
    url: `/admin/jobs/${uuid}`
  });
});
const createJob = () => __async(void 0, null, function* () {
  return Vue.axios({
    method: "POST",
    url: "/admin/coupons/imports",
    data: {}
  });
});
export default {
  listJobs,
  listJob,
  createJob
};
