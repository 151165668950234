"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import testerService from "@/store/services/tester";
let TesterModule = class extends VuexModule {
  constructor() {
    super(...arguments);
    __publicField(this, "testers", []);
    __publicField(this, "pagingInfo", { currentPage: 1, totalPage: 1 });
    __publicField(this, "testersLoaded", false);
    __publicField(this, "testersLoading", true);
    __publicField(this, "error", null);
  }
  listTesters(params) {
    return __async(this, null, function* () {
      this.context.commit("mutateTestersLoadingStatus");
      const { data } = yield testerService.listTesters(params);
      this.context.commit("mutateTesters", data);
    });
  }
  addTester(email) {
    return __async(this, null, function* () {
      yield testerService.addTester(email);
    });
  }
  removeTester(id) {
    return __async(this, null, function* () {
      yield testerService.deleteTester(id);
    });
  }
  setError(error) {
    this.context.commit("mutateError", error);
  }
  mutateTesters(testers) {
    this.testers = testers.items || [];
    this.pagingInfo = testers.pagingInfo || { currentPage: 1, totalPage: 1 };
    this.testersLoaded = true;
    this.testersLoading = false;
  }
  mutateTestersLoadingStatus() {
    this.testersLoading = true;
  }
  mutateError(error) {
    this.error = error;
  }
  get getError() {
    return this.error;
  }
  get getTesters() {
    return this.testers;
  }
  get getPagingInfo() {
    return this.pagingInfo;
  }
  get isTestersLoaded() {
    return this.testersLoaded;
  }
  get isTestersLoading() {
    return this.testersLoading;
  }
};
__decorateClass([
  Action({ rawError: true })
], TesterModule.prototype, "listTesters", 1);
__decorateClass([
  Action({ rawError: true })
], TesterModule.prototype, "addTester", 1);
__decorateClass([
  Action({ rawError: true })
], TesterModule.prototype, "removeTester", 1);
__decorateClass([
  Action({ rawError: true })
], TesterModule.prototype, "setError", 1);
__decorateClass([
  Mutation
], TesterModule.prototype, "mutateTesters", 1);
__decorateClass([
  Mutation
], TesterModule.prototype, "mutateTestersLoadingStatus", 1);
__decorateClass([
  Mutation
], TesterModule.prototype, "mutateError", 1);
TesterModule = __decorateClass([
  Module({ name: "tester", namespaced: true })
], TesterModule);
export {
  TesterModule as default
};
