"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import magazineService from "@/store/services/magazine";
let MagazineModule = class extends VuexModule {
  constructor() {
    super(...arguments);
    __publicField(this, "magazines", []);
    __publicField(this, "magazinesLoaded", false);
    __publicField(this, "updatingMagazines", false);
    __publicField(this, "magazinesById", {});
  }
  listMagazines() {
    return __async(this, null, function* () {
      this.context.commit("mutateUpdatingMagazines");
      const { data } = yield magazineService.listMagazines();
      this.context.commit("mutateMagazines", data);
    });
  }
  createMagazine(magazine) {
    return __async(this, null, function* () {
      const { data } = yield magazineService.createMagazine(magazine);
      return data;
    });
  }
  updateMagazine(_0) {
    return __async(this, arguments, function* ({ id, magazine }) {
      yield magazineService.updateMagazine(id, magazine);
    });
  }
  deleteMagazine(_0) {
    return __async(this, arguments, function* ({ id }) {
      yield magazineService.deleteMagazine(id);
    });
  }
  updateMagazineOrder(_0) {
    return __async(this, arguments, function* ({ id, magazine }) {
      this.context.commit("mutateUpdatingMagazines");
      yield magazineService.updateMagazine(id, magazine);
      this.context.commit("mutateUpdatingMagazines", false);
    });
  }
  mutateMagazines(magazines) {
    this.magazines = magazines.sort((a, b) => a.order > b.order ? 1 : -1);
    this.magazinesById = magazines.reduce((acc, magazine) => __spreadProps(__spreadValues({}, acc), { [magazine.id]: magazine }), {});
    this.magazinesLoaded = true;
    this.updatingMagazines = false;
  }
  mutateLoadedStatus(status = false) {
    this.magazinesLoaded = status;
  }
  mutateUpdatingMagazines(status = true) {
    this.updatingMagazines = status;
  }
  get getMagazines() {
    return this.magazines || [];
  }
  get isMagazinesLoaded() {
    return this.magazinesLoaded;
  }
  get isMagazinesUpdating() {
    return this.updatingMagazines;
  }
  get getMagazineById() {
    return (magazineId) => this.magazinesById[magazineId] || {};
  }
};
__decorateClass([
  Action({ rawError: true })
], MagazineModule.prototype, "listMagazines", 1);
__decorateClass([
  Action({ rawError: true })
], MagazineModule.prototype, "createMagazine", 1);
__decorateClass([
  Action({ rawError: true })
], MagazineModule.prototype, "updateMagazine", 1);
__decorateClass([
  Action({ rawError: true })
], MagazineModule.prototype, "deleteMagazine", 1);
__decorateClass([
  Action({ rawError: true })
], MagazineModule.prototype, "updateMagazineOrder", 1);
__decorateClass([
  Mutation
], MagazineModule.prototype, "mutateMagazines", 1);
__decorateClass([
  Mutation
], MagazineModule.prototype, "mutateLoadedStatus", 1);
__decorateClass([
  Mutation
], MagazineModule.prototype, "mutateUpdatingMagazines", 1);
MagazineModule = __decorateClass([
  Module({ name: "magazine", namespaced: true })
], MagazineModule);
export {
  MagazineModule as default
};
