"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Vue } from "vue-property-decorator";
import config from "@/config";
const getApi = () => __async(void 0, null, function* () {
  const baseUrl = new URL(config.API_URL).host;
  return Vue.axios({
    method: "GET",
    baseURL: `https://${baseUrl}`
  });
});
const clearCloudfrontCache = () => __async(void 0, null, function* () {
  return Vue.axios({
    method: "POST",
    url: "/admin/cache/cloudfront/clear",
    data: {}
  });
});
export default {
  getApi,
  clearCloudfrontCache
};
