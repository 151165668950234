"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Vue } from "vue-property-decorator";
const listCodes = (params) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "GET",
    url: "/admin/promo-codes",
    params
  });
});
const listStats = () => __async(void 0, null, function* () {
  return Vue.axios({
    method: "GET",
    url: "/admin/promo-codes/stats"
  });
});
const listMainBatch = () => __async(void 0, null, function* () {
  return Vue.axios({
    method: "GET",
    url: "/admin/promo-codes/main-batch"
  });
});
const createCodes = (data) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "POST",
    url: "/admin/promo-codes",
    data
  });
});
const generateExcel = (id) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "POST",
    url: `/admin/promo-codes/excel/${id}`,
    data: {}
  });
});
const generateMainBatch = () => __async(void 0, null, function* () {
  return Vue.axios({
    method: "POST",
    url: `/admin/promo-codes/excel/main-batch`,
    data: {}
  });
});
const deleteCodes = (id) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "DELETE",
    url: `/admin/promo-codes/${id}`
  });
});
export default {
  listCodes,
  listMainBatch,
  listStats,
  createCodes,
  generateExcel,
  generateMainBatch,
  deleteCodes
};
