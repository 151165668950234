"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import advertisementService from "@/store/services/advertisement";
let AdvertisementModule = class extends VuexModule {
  constructor() {
    super(...arguments);
    __publicField(this, "advertisements", []);
    __publicField(this, "advertisementsLoaded", false);
    __publicField(this, "advertisementsById", {});
  }
  listAdvertisements() {
    return __async(this, null, function* () {
      this.context.commit("mutateLoadedStatus");
      const { data } = yield advertisementService.listAdvertisements();
      this.context.commit("mutateAds", data);
    });
  }
  createAdvertisement(advertisement) {
    return __async(this, null, function* () {
      const { data } = yield advertisementService.createAdvertisement(advertisement);
      return data;
    });
  }
  updateAdvertisement(_0) {
    return __async(this, arguments, function* ({ id, advertisement }) {
      yield advertisementService.updateAdvertisement(id, advertisement);
    });
  }
  deleteAdvertisement(_0) {
    return __async(this, arguments, function* ({ id }) {
      yield advertisementService.deleteAdvertisement(id);
    });
  }
  mutateAds(advertisements) {
    this.advertisements = advertisements;
    this.advertisementsById = advertisements.reduce((acc, ad) => __spreadProps(__spreadValues({}, acc), { [ad.id]: ad }), {});
    this.advertisementsLoaded = true;
  }
  mutateLoadedStatus(status = false) {
    this.advertisementsLoaded = status;
  }
  get getAdvertisements() {
    return this.advertisements || [];
  }
  get isAdvertisementsLoaded() {
    return this.advertisementsLoaded;
  }
  get getAdvertisementById() {
    return (advertisementId) => this.advertisementsById[advertisementId] || {};
  }
};
__decorateClass([
  Action({ rawError: true })
], AdvertisementModule.prototype, "listAdvertisements", 1);
__decorateClass([
  Action({ rawError: true })
], AdvertisementModule.prototype, "createAdvertisement", 1);
__decorateClass([
  Action({ rawError: true })
], AdvertisementModule.prototype, "updateAdvertisement", 1);
__decorateClass([
  Action({ rawError: true })
], AdvertisementModule.prototype, "deleteAdvertisement", 1);
__decorateClass([
  Mutation
], AdvertisementModule.prototype, "mutateAds", 1);
__decorateClass([
  Mutation
], AdvertisementModule.prototype, "mutateLoadedStatus", 1);
AdvertisementModule = __decorateClass([
  Module({ name: "advertisement", namespaced: true })
], AdvertisementModule);
export {
  AdvertisementModule as default
};
