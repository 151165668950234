"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Vue } from "vue-property-decorator";
import { getFormData } from "@/utils/helpers";
const listMagazines = () => __async(void 0, null, function* () {
  return Vue.axios({
    method: "GET",
    url: "/admin/magazines"
  });
});
const updateMagazine = (id, magazine) => __async(void 0, null, function* () {
  const formData = getFormData(magazine);
  return Vue.axios({
    headers: { "content-type": "multipart/form-data" },
    method: "PATCH",
    url: `/admin/magazines/${id}`,
    data: formData
  });
});
const createMagazine = (magazine) => __async(void 0, null, function* () {
  const formData = getFormData(magazine);
  return Vue.axios({
    headers: { "content-type": "multipart/form-data" },
    method: "POST",
    url: `/admin/magazines/`,
    data: formData
  });
});
const deleteMagazine = (id) => __async(void 0, null, function* () {
  return Vue.axios({
    method: "DELETE",
    url: `/admin/magazines/${id}`
  });
});
export default {
  listMagazines,
  createMagazine,
  updateMagazine,
  deleteMagazine
};
