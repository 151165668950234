"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import trackService from "@/store/services/track";
let TrackModule = class extends VuexModule {
  constructor() {
    super(...arguments);
    __publicField(this, "trackItems", []);
    __publicField(this, "pagingInfo", { currentPage: 1, totalPage: 1 });
    __publicField(this, "tracksLoaded", false);
    __publicField(this, "tracksLoading", false);
  }
  listTracks(params) {
    return __async(this, null, function* () {
      this.context.commit("mutateLoadingStatus");
      const { data } = yield trackService.listTracks(params);
      this.context.commit("mutateTracks", data);
    });
  }
  getTrack(id) {
    return __async(this, null, function* () {
      const { data } = yield trackService.getTrack(id);
      return data;
    });
  }
  mutateTracks(tracks) {
    this.trackItems = tracks.items || [];
    this.pagingInfo = tracks.pagingInfo || { currentPage: 1, totalPage: 1 };
    this.tracksLoaded = true;
    this.tracksLoading = false;
  }
  mutateLoadingStatus() {
    this.tracksLoading = true;
  }
  get getTrackItems() {
    return this.trackItems;
  }
  get getPagingInfo() {
    return this.pagingInfo;
  }
  get isTracksLoaded() {
    return this.tracksLoaded;
  }
  get isTracksLoading() {
    return this.tracksLoading;
  }
};
__decorateClass([
  Action({ rawError: true })
], TrackModule.prototype, "listTracks", 1);
__decorateClass([
  Action({ rawError: true })
], TrackModule.prototype, "getTrack", 1);
__decorateClass([
  Mutation
], TrackModule.prototype, "mutateTracks", 1);
__decorateClass([
  Mutation
], TrackModule.prototype, "mutateLoadingStatus", 1);
TrackModule = __decorateClass([
  Module({ name: "track", namespaced: true })
], TrackModule);
export {
  TrackModule as default
};
