var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import config from "@/config";
import { apiModule } from "@/store";
import ClearCacheModal from "@/components/ui/common/ClearCacheModal.vue";
let Navigation = class extends Vue {
  constructor() {
    super(...arguments);
    this.menu = [
      {
        title: this.$t("layout.events"),
        id: "events",
        relatives: ["event-details", "archive-event-details"],
        items: [
          {
            title: this.$t("events.active"),
            id: "events",
            pathName: "events"
          },
          {
            title: this.$t("events.archive"),
            id: "archive-events",
            pathName: "archive-events"
          }
        ]
      },
      {
        title: this.$t("layout.magazines"),
        id: "magazines",
        relatives: ["magazine-details"],
        pathName: "magazines"
      },
      {
        title: this.$t("layout.products"),
        id: "products",
        items: [
          {
            title: this.$t("layout.promoCode"),
            id: "promocode",
            pathName: "promocode"
          },
          {
            title: this.$t("products.iapProducts"),
            id: "products",
            pathName: "products"
          }
        ]
      },
      {
        title: this.$t("layout.application"),
        id: "application",
        relatives: ["collection-details"],
        items: [
          {
            title: this.$t("layout.coupons"),
            id: "coupons",
            pathName: "coupons"
          },
          {
            title: this.$t("layout.collections"),
            id: "collections",
            pathName: "collections"
          },
          {
            title: this.$t("application.homeScreen"),
            id: "homescreen",
            pathName: "homescreen"
          },
          {
            title: this.$t("layout.featuredTags"),
            id: "tags",
            pathName: "tags"
          },
          {
            title: this.$t("layout.translations"),
            id: "translations",
            pathName: "translations"
          },
          {
            title: this.$t("layout.contents"),
            id: "contents",
            pathName: "contents"
          },
          {
            title: this.$t("layout.advertisements"),
            id: "advertisements",
            pathName: "advertisements"
          },
          {
            title: this.$t("layout.universeCircles"),
            id: "universe-circles",
            pathName: "universe-circles"
          },
          {
            title: this.$t("layout.testability"),
            id: "testability",
            pathName: "testability"
          },
          {
            title: this.$t("application.clearCache"),
            id: "cache",
            action: () => this.$bvModal.show("clear-cache-modal")
          }
        ]
      },
      {
        title: this.$t("statistics.measurements"),
        id: "measurements",
        items: [
          {
            title: this.$t("statistics.redeemStats"),
            id: "redeem-stats",
            pathName: "redeem-stats"
          },
          {
            title: this.$t("statistics.userStats"),
            id: "subscription-stats",
            pathName: "subscription-stats"
          },
          {
            title: this.$t("statistics.queryUsers"),
            id: "subscription-export",
            pathName: "subscription-export"
          }
        ]
      },
      {
        title: this.$t("layout.reports"),
        id: "reports",
        pathName: "reports"
      },
      {
        title: this.$t("layout.settings"),
        id: "settings",
        items: [
          {
            title: this.$t("settings.users"),
            id: "users",
            pathName: "users"
          },
          {
            title: this.$t("settings.testers"),
            id: "testers",
            pathName: "testers"
          },
          {
            title: this.$t("settings.history"),
            id: "tracks",
            pathName: "tracks"
          },
          {
            title: this.$t("layout.sync"),
            id: "sync",
            pathName: "sync"
          },
          {
            title: this.$t("layout.logout"),
            id: "logout",
            pathName: "logout"
          }
        ],
        text: this.versionTexts
      }
    ];
    this.selectedApp = "glamour";
    this.selectOptions = [
      { value: "glamour", text: "Glamour" },
      { value: "kiskegyed", text: "Kiskegyed" },
      { value: "egeszsegkalauz", text: "Egy\xE9szs\xE9gKalauz" }
    ];
  }
  get version() {
    return config.PACKAGE_VERSION || "";
  }
  get gitHash() {
    return config.GIT_HASH || "";
  }
  get apiVersion() {
    return apiModule.getApiVersion;
  }
  get apiBuild() {
    return apiModule.getApiBuild;
  }
  get versionTexts() {
    if (this.apiVersion.length) {
      return this.apiBuild.length ? [`Admin: v${this.version} (${this.gitHash})`, `API: v${this.apiVersion} (${this.apiBuild})`] : [`Admin: v${this.version} (${this.gitHash})`, `API: v${this.apiVersion}`];
    }
    return [`Admin: v${this.version} (${this.gitHash})`];
  }
  activeMenu(nav) {
    const hasActiveRelative = nav.relatives ? nav.relatives.includes(this.$route.name) : false;
    return this.$route.name === nav.pathName || hasActiveRelative;
  }
  activeDropdown(nav) {
    const hasActiveRelative = nav.relatives ? nav.relatives.includes(this.$route.name) : false;
    return nav.items ? nav.items.some((i) => i.pathName === this.$route.name) || hasActiveRelative : false;
  }
};
Navigation = __decorateClass([
  Component({
    name: "Navigation",
    components: {
      ClearCacheModal
    }
  })
], Navigation);
export {
  Navigation as default
};
