"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import apiService from "@/store/services/api";
import config from "@/config";
let ApiModule = class extends VuexModule {
  constructor() {
    super(...arguments);
    __publicField(this, "api", {});
    __publicField(this, "clearingCacheStatus", false);
    __publicField(this, "version", "");
  }
  getApi() {
    return __async(this, null, function* () {
      const { data } = yield apiService.getApi();
      this.context.commit("mutateApi", data);
    });
  }
  clearCloudfrontCache() {
    return __async(this, null, function* () {
      this.context.commit("mutateClearingCacheStatus", true);
      try {
        yield apiService.clearCloudfrontCache();
      } finally {
        this.context.commit("mutateClearingCacheStatus", false);
      }
    });
  }
  setVersion() {
    this.context.commit("mutateVersion");
  }
  mutateApi(data) {
    this.api = data.api;
  }
  mutateClearingCacheStatus(status) {
    this.clearingCacheStatus = status;
  }
  mutateVersion() {
    this.version = config.PACKAGE_VERSION || "";
  }
  get getVersion() {
    return this.version;
  }
  get getApiVersion() {
    return this.api.version || "";
  }
  get getApiBuild() {
    return this.api.build || "";
  }
  get getClearingCacheStatus() {
    return this.clearingCacheStatus;
  }
};
__decorateClass([
  Action({ rawError: true })
], ApiModule.prototype, "getApi", 1);
__decorateClass([
  Action({ rawError: true })
], ApiModule.prototype, "clearCloudfrontCache", 1);
__decorateClass([
  Action
], ApiModule.prototype, "setVersion", 1);
__decorateClass([
  Mutation
], ApiModule.prototype, "mutateApi", 1);
__decorateClass([
  Mutation
], ApiModule.prototype, "mutateClearingCacheStatus", 1);
__decorateClass([
  Mutation
], ApiModule.prototype, "mutateVersion", 1);
ApiModule = __decorateClass([
  Module({ name: "api", namespaced: true })
], ApiModule);
export {
  ApiModule as default
};
