"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import translationService from "@/store/services/translation";
let TranslationModule = class extends VuexModule {
  constructor() {
    super(...arguments);
    __publicField(this, "translationItems", []);
    __publicField(this, "pagingInfo", { currentPage: 1, totalPage: 1 });
    __publicField(this, "translationsLoaded", false);
    __publicField(this, "loading", false);
  }
  listTranslations(params) {
    return __async(this, null, function* () {
      this.context.commit("mutateLoadingStatus");
      const { data } = yield translationService.listTranslations(params);
      this.context.commit("mutateTranslations", data);
    });
  }
  createTranslation(translation) {
    return __async(this, null, function* () {
      const { data } = yield translationService.createTranslation(translation);
      return data;
    });
  }
  deleteTranslation(_0) {
    return __async(this, arguments, function* ({ key }) {
      yield translationService.deleteTranslation(key);
    });
  }
  getTranslationFile(params) {
    return __async(this, null, function* () {
      const { data } = yield translationService.getTranslationFile(params);
      return data;
    });
  }
  syncTranslations() {
    return __async(this, null, function* () {
      yield translationService.syncTranslations();
    });
  }
  mutateTranslations(translations) {
    this.translationItems = translations.items || [];
    this.pagingInfo = translations.pagingInfo || { currentPage: 1, totalPage: 1 };
    this.translationsLoaded = true;
    this.loading = false;
  }
  mutateLoadingStatus() {
    this.loading = true;
  }
  get getTranslationItems() {
    return this.translationItems;
  }
  get getPagingInfo() {
    return this.pagingInfo;
  }
  get isTranslationsLoaded() {
    return this.translationsLoaded;
  }
  get isLoading() {
    return this.loading;
  }
};
__decorateClass([
  Action({ rawError: true })
], TranslationModule.prototype, "listTranslations", 1);
__decorateClass([
  Action({ rawError: true })
], TranslationModule.prototype, "createTranslation", 1);
__decorateClass([
  Action({ rawError: true })
], TranslationModule.prototype, "deleteTranslation", 1);
__decorateClass([
  Action({ rawError: true })
], TranslationModule.prototype, "getTranslationFile", 1);
__decorateClass([
  Action({ rawError: true })
], TranslationModule.prototype, "syncTranslations", 1);
__decorateClass([
  Mutation
], TranslationModule.prototype, "mutateTranslations", 1);
__decorateClass([
  Mutation
], TranslationModule.prototype, "mutateLoadingStatus", 1);
TranslationModule = __decorateClass([
  Module({ name: "translation", namespaced: true })
], TranslationModule);
export {
  TranslationModule as default
};
