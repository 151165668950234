var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Vue, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import { appModule } from "@/store";
let ErrorModal = class extends Vue {
  get error() {
    return appModule.getError;
  }
  get modalTitle() {
    return this.error && !!this.error.errorMessage && this.error.errorMessage.length > 0 ? `${this.$t("layout.errorOccurred")}: ${this.error.errorMessage}` : this.$t("layout.errorOccurred");
  }
  get errorVisible() {
    return this.error && Object.keys(this.error).length !== 0;
  }
  get formattedError() {
    return JSON.stringify(this.error, void 0, 2);
  }
  resetError() {
    if (this.errorVisible && this.$route.name === "login") {
      appModule.clearError();
    }
  }
  hideError() {
    appModule.clearError();
    this.$bvModal.hide("error-modal");
  }
};
__decorateClass([
  Watch("error")
], ErrorModal.prototype, "resetError", 1);
ErrorModal = __decorateClass([
  Component({
    name: "ErrorModal"
  })
], ErrorModal);
export {
  ErrorModal as default
};
